<template>
  <div class="home">
    <HelloWorld message="Error 404 Not Found" />
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "PageNotFound",
  components: {
    HelloWorld
  }
};
</script>
