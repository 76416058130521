<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/logo.webp')"
          class="my-3"
          contain
          height="200"
        />
      </v-col>

      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
التسجيل الإلكترونى للتربية العسكرية         </h1>

        <p class="subheading font-weight-regular">
لطلاب جامعة أسوان        </p>
      </v-col>

      <v-col class="mt-n7" cols="12">
        <h1>{{ message }}</h1>
      </v-col>

      <v-col class="mb-5" cols="12">
        <h2 class="headline font-weight-bold mb-3">
          خدمات الموقع
        </h2>

        <v-row dir="rtl" justify="center">
          <router-link
            v-for="(eco, i) in ecosystem"
            :key="i"
            :to="eco.href"
            class="subheading mx-3"
            >{{ eco.text }}</router-link
          >
          <a
            v-for="eco in ecosystemlinks"
            :key="eco.href"
            :href="eco.href"
            class="subheading mx-3"
          >
            {{ eco.text }}
          </a>
        </v-row>

        <v-col class="mb-5" cols="12">
          <h2 class="headline font-weight-bold mb-3">
            روابط هامة
          </h2>

          <v-row dir="rtl" justify="center">
            <a
              v-for="link in importantLinks"
              :key="link.href"
              :href="link.href"
              class="subheading mx-3"
              target="_blank"
            >
              {{ link.text }}
            </a>
          </v-row>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "HelloWorld",
  props: ["message"],
  data: () => ({
    ecosystem: [
      {
        text: "التربية العسكرية",
        href: { name: "military" }
      },
      {
        text: "تسجيل التربية العسكرية",
        href: { name: "militaryRegister" }
      }
    ],
    ecosystemlinks: [
      {
        text: "المقبولين بالتربية العسكرية",
        href: "/military/accepted.html"
      }
    ],
    importantLinks: [
      // {
      //   text: "موقع الجامعة",
      //   href: "http://aswu.edu.eg/"
      // },
      {
        text: "منصة التعلم الالكتروني",
        href: "https://elc.aswu.edu.eg/"
      }
    ]
  })
};
</script>
